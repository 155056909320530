// src/teamData.js

const teamMembers = [
    {
      name: "Reyan Anwar",
      role: "Team Captain",
      description: "Potentially the greatest captain in TMAD's history (emphasis on 'potentially'). 5th year aerospace student " + 
                    "with both an intolerance and addiction to dairy. Ex-Senior wing lead (2021-2023). CATIA Demon (Certified). " +
                    "Peak Toad (Mario) Impersonations.",
      image: "team_pictures/Reyan-Anwar.JPG",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/reyan_al_buni",
        linkedin: "https://www.linkedin.com/in/reyan-anwar/"
      }
    },
    {
        name: "Justin Sam Kumplapallil",
        role: "Manager",
        description: "Addicted to riding my motorcycle, procrastinate by playing my guitar, and Pandas.",
        image: "team_pictures/Justin-Sam-Kumplapallil.PNG",
        social: {
          facebook: "",
          twitter: "",
          instagram: "https://www.instagram.com/just1n.s_",
          linkedin: "https://www.linkedin.com/in/justin-sam-kumplapallil-9161b918a/"
        }
    },  
    {
      name: "Minsu Joo",
      role: "Design Analysis and Experimental Validations Lead/Technical Advisor ",
      description: "Graduate Student in Aerospace Engineering with specialization in Low-Speed Aerodynamics and Wind Tunnel Testing.",
      image: "team_pictures/Minsu-Joo.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/lieutenantminus/",
        linkedin: "https://www.linkedin.com/in/minsu-joo-761648a6"
      }
    },
    {
      name: "Julian Bardin",
      role: "Design Advisor",
      description: "Graduate student and former flight mechanics lead, specializing in aircraft design optimization and tool development.",
      image: "team_pictures/Julian-Bardin.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/juliancbardin/"
      }
    },
    {
      name: "Alex Alfredo Aguilar",
      role: "Fuselage Lead",
      description: "In my 4th Year of study going into the aircraft stream and I’ve been a part of the team for 3 years.",
      image: "team_pictures/Alex-Alfredo-Aguilar.png",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/fultra_",
        linkedin: "https://www.linkedin.com/in/aalguilar/"
      }
    },
    {
      name: "Nayoung Kim",
      role: "Senior Tail Lead",
      description: "4th year undergraduate Mechanical Engineering student. CA uncure specialist.",
      image: "team_pictures/Nayoung-Kim.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/nayoung_kim21",
        linkedin: "https://www.linkedin.com/in/nayoung--kim"
      }
    },
    {
        name: "Saket Bharade",
        role: "Avionics Project Manager",
        description: "Designing, Managing and delivering Avionics Solutions.",
        image: "team_pictures/Saket-Bharade.jpg",
        social: {
          facebook: "",
          twitter: "",
          instagram: "https://www.instagram.com/__saket_",
          linkedin: "https://www.linkedin.com/in/saket-bharade-86b1a3180/"
        }
    },
    {
      name: "Romit Sagu",
      role: "Avionics Specialist",
      description: "I am a fifth-year undergraduate student specializing in Computer Engineering with a focus on Software. "+
                    "My background in both computer hardware and software systems enables me to contribute effectively in various capacities, "+
                    "including the development of this site. ",
      image: "team_pictures/Romit-Sagu.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/romit-sagu"
      }
    },
    {
      name: "Minsu Kim",
      role: "PADA Avionics Lead",
      description: "Motivated 3rd year Aerospace student.",
      image: "team_pictures/Minsu-Kim.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/bigbread_su/",
        linkedin: "https://www.linkedin.com/in/minsu-kim-39955b165/"
      }
    },
    {
      name: "Nishad Awasthy",
      role: "DAS Avionics Jr Lead",
      description: "3rd year electrical engineering student and avid aerospace larper.",
      image: "team_pictures/Nishad-Awasthy.png",
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/nishad-awasthy-581a13258/"
      }
    },
    {
      name: "Leo Tomarchio",
      role: "PADA Avionics Jr Lead",
      description: "3rd year aerospace engineer, specializing in trial and (mostly) error.",
      image: "team_pictures/Leo-Tomarchio.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "https://ca.linkedin.com/in/leandro-tomarchio"
      }
    },
    {
        name: "Anh Quan Luu",
        role: "PADA Structure Lead",
        description: "In love with Boba Lover.",
        image: "team_pictures/Anh-Quan-Luu.jpg",
        social: {
          facebook: "https://www.facebook.com/quan.luuanh.9?mibextid=LQQJ4d",
          twitter: "",
          instagram: "https://www.instagram.com/aquan_lwu",
          linkedin: "https://www.linkedin.com/in/anhquanluu"
        }
    },
    {
      name: "Jeongyun Jeong",
      role: "PADA Structure Lead",
      description: "Hi, My name is Jeongyun Jeong. I was GTV Lead in 2022-23 and Team Manager 2023-24. I will be helping the team as PADA Structure CO-designer with Quan as well as composite works.",
      image: "team_pictures/Jeongyun-Jeong.jpg",
      social: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/jeongyun-jeong-37100b295/"
      }
    },
    {
        name: "Krishna Patel",
        role: "Wing Lead",
        description: "Greetings, my name is Krishna. I am going into my fourth year of aerospace engineering and I’ll be your wing lead for this year.",
        image: "team_pictures/Krishna-Patel.jpg",
        social: {
          facebook: "",
          twitter: "",
          instagram: "https://www.instagram.com/krishhna_",
          linkedin: "https://www.linkedin.com/in/krishna-patel-926557290"
        }
    },
    {
        name: "Siyona Singh Rathore",
        role: "Fuselage Jr Lead",
        description: "2nd year Aerospace student. Epoxy beast. Impractical plane enthusiast.",
        image: "team_pictures/Siyona-Singh-Rathore.jpg",
        social: {
          facebook: "",
          twitter: "",
          instagram: "https://www.instagram.com/iwantanicepage",
          linkedin: ""
        }
    },
    {
        name: "Darren Chu",
        role: "Micro-Class Manager",
        description: "3rd year Aerospace Engineering Student. I am the micro class team manager in charge of coordinating junior leads and generals in the SAE micro class competition. I look forward to seeing you all!",
        image: "team_pictures/Darren-Chu.PNG",
        social: {
          facebook: "",
          twitter: "",
          instagram: "",
          linkedin: "https://www.linkedin.com/in/darren-chu-712311271/"
        }
    },
    {
      name: "Sharon Ebenesar",
      role: "Graphic Designer",
      description: "Hello!! I’m going into my third year of Aerospace Engineering and I’m going to be the graphics designer for the team. ",
      image: "team_pictures/Sharon-Ebenesar.png",
      social: {
        facebook: "",
        twitter: "",
        instagram: "https://www.instagram.com/sharon_ebenesar",
        linkedin: "https://www.linkedin.com/in/sharon-ebenesar-9b25a0267/"
      }
  }
  ];
  
  export default teamMembers;
  